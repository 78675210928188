import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getApplicationData,
  verifyApplication,
  updateApplicationStatus,
  updateEmploymentData,
  updateSelectedTradelines,
  getApplicationApr,
  uploadDocuments,
  joinDebtConsolidationWaitlist,
  updateAdditionalFunds,
} from 'thunks';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { CurrentFlow } from 'enums/CurrentFlow';
import { CompanyProduct } from 'enums/CompanyProduct';
import { UtmSource } from 'enums/UtmTagName';
import { VerificationResult } from 'api/LoanOfferApi';
import { CardColor } from 'components/CardFlow/Customize/cardVersions';
import { VerificationStep, VerificationStepProgress } from 'components/Verification/verificationSteps';
import { UploadDocumentsResponse } from 'api/DocumentsApi';
import { ProfessionGroup } from 'enums/ProfessionGroup';
import { PayrollProviderName } from 'api/EmployersApi';

import { ReferralProgram } from './cardData';

export interface HardOfferData {
  payOff: Tradeline[];
  keepIt: Tradeline[];
  cantConsolidate: Tradeline[];
  payOffTotal: HardOfferTotal;
  additionalFundsTotal: HardOfferTotal;
  loanTotal: HardOfferTotal;
  planneryLoan: HardOfferTotal;
  offerSummary: HardOfferSummary;
  manuallyUpdatedSelections: boolean;
  manuallyUpdatedAdditionalFunds: boolean;
}

export enum DebtRecommendation {
  KeepIt = 'keep-it',
  PayOff = 'pay-off',
}

export enum DebtName {
  PersonalLoan = 'PersonalLoan',
  CreditCard = 'CreditCard',
  StudentLoan = 'StudentLoan',
  AutoLoan = 'AutoLoan',
  Mortgage = 'Mortgage',
}

export interface DebtSummary {
  [LoanType.PersonalLoan]: Debt;
  [LoanType.CreditCard]: Debt;
  [LoanType.StudentLoan]: Debt;
  [LoanType.AutoLoan]: Debt;
  [LoanType.Mortgage]: Debt;
}

export interface Debt {
  name: DebtName;
  totalBalance: number | null;
  totalConsolidatableBalance: number | null;
  apr: number | null;
  recommendation: DebtRecommendation;
  tradelines: Tradeline[];
  cardOffer?: CardOffer;
}

interface CardOffer {
  apr: number;
  totalInterest: number;
  primaryCardId: string;
}

export interface HardOfferTotal {
  amount: number;
  apr: number;
  term: number;
  financeCharge: number;
  monthlyPayment: number;
}

export interface HardOfferSummary {
  loanAmount: number;
  moneySaved?: number;
  monthsSaved?: number;
  monthlyPaymentSaved?: number;
}

export enum LoanType {
  PersonalLoan = 'personal-loan',
  CreditCard = 'credit-card',
  MedicalLoan = 'medical-loan',
  StudentLoan = 'student-loan',
  AutoLoan = 'auto-loan',
  Mortgage = 'mortgage',
  Other = 'other',
}

export enum LoanTypeHumanized {
  PersonalLoan = 'Personal Loan',
  CreditCard = 'Credit Card',
  MedicalLoan = 'Medical Loan',
  StudentLoan = 'Student Loan',
  AutoLoan = 'Auto Loan',
  Mortgage = 'Mortgage',
  Other = 'Other',
}

export enum Recommendation {
  PayOff = 'Pay Off',
  KeepIt = 'Keep it as is',
  CantConsolidate = "Can't consolidate",
}

export enum TradeLineStatus {
  Open = 'open',
  Closed = 'closed',
  Unknown = 'unknown',
}

export enum TradeLineErrCode {
  MissingTerm = 'missing-term',
  MissingBalance = 'missing-balance',
  MissingMonthlyPayment = 'missing-monthly-payment',

  EstimatedApr = 'estimated-apr',
  AprOutOfRange = 'apr-out-of-range',

  CantCalculateCreditCardTerm = 'cant-calculate-credit-card-term',
  CreditCardTermOufOfRange = 'credit-card-term-out-of-range',
}

export enum Source {
  CBC = 'CBC',
  Method = 'Method',
  CBCAndMethod = 'CBC and Method',
}
export interface Tradeline {
  id: string;
  firm: string;
  opened: string | null;
  originalBalance: number | null;
  balance: number;
  balanceToConsolidate: number;
  monthlyPayment: number | null;
  loanType: LoanType;
  term: number | null;
  status: TradeLineStatus;
  apr: number;
  recommendation: Recommendation;
  selectedForConsolidation: boolean | null;

  errorCode: string[] | null;

  accountMask?: string | null;
  elapsedMonths?: number | null;
  remainingMonths?: number | null;
  partialInterest?: number | null;
  totalInterest?: number | null;
  planneryInterest?: number | null;
  savings?: number | null;

  methodAccountId?: string;
  logoUrl?: string;
  weWillPay?: boolean;

  source: Source | null;
}

export interface TradeLinesReport {
  proposed: Tradeline[];
  current: Tradeline[];
  other: Tradeline[];
}

export interface CreateApplicationData {
  status: ApplicationStatusName;
  product: CompanyProduct;
  borrowerFirstName: string;
  borrowerLastName: string;
  borrowerStreetAddress: string;
  borrowerCity: string;
  borrowerStateOrProvince: string;
  borrowerZipOrPostalCode: string;
  borrowerDateOfBirth: string;
  borrowerEmail: string;
  phoneNumber: string;
  loanAmountRange: string;
  creditScoreRange: string;
}

export interface GetApplicationData {
  id: string;
  status: ApplicationStatusName;
  createdAt: string;
  currentFlow: CurrentFlow;
  borrowerId: string;
  borrowerCitizenship?: string;
  borrowerFirstName?: string;
  borrowerLastName?: string;
  borrowerStateOrProvince?: string;
  borrowerCredentials?: string;
  borrowerPersonalIdNumberVerificationAttempted: boolean;
  calculatedLoanAmount?: number;
  loanTermInMonths?: number;
  amountPerPaycheck?: number;
  loanAmount?: number;
  maxLoanAmount: number;
  minLoanAmount?: number;
  firstDeductionDate?: string;
  monthlyLoanPayment?: number;
  borrowerEmail?: string;
  originationFee?: number;
  documentaryStampTaxFee?: number;
  plaidTokenLastUpdated?: string;
  bankName?: string;
  accountMask?: string;
  dateOfLoan?: string;
  feedbackExperience?: string;
  feedbackHowDidYouHear?: string;
  hardOffer?: string | HardOfferData;
  additionalFundsEligibility: boolean;
  debtSummary?: string | DebtSummary;
  utmSource?: UtmSource;
  cardApplied?: boolean;
  cardReferralLink?: string;
  cardWaitListPosition?: number;
  cardOriginalWaitListPosition?: number;
  referralProgram?: ReferralProgram;
  cardColor?: CardColor;
  employment: {
    employer1: Employer;
    employer2: Employer;
    isLicensedProfessional: boolean;
    professionGroup: ProfessionGroup;
  };
  totalAnnualIncome?: number;
  manualDeductionEmailSent?: boolean;
  completedRepaymentAccountStep?: boolean;
  fullDepositSwitch?: boolean;
  creditScore?: number;
  hasSignedACHForms?: boolean;
  missedPaymentReason?: string;
  missedPaymentAmount?: number;
  missedPaymentDate?: string;
  lateFee?: number;
  verificationStepProgress?: VerificationStepProgress;
  partner?: string;
  studentLoanAssistanceAppointment?: StudentLoanAssistanceAppointment;
  studentLoanAssistancePaymentState?: StudentLoanAssistancePaymentState;
  onDebtConsolidationWaitlist?: boolean;
}

export interface StudentLoanAssistanceAppointment {
  date: Date;
  rescheduleLink?: string;
  cancelLink?: string;
}

export interface StudentLoanAssistancePaymentState {
  paymentPlan: StudentLoanAssistancePaymentPlan;
  status: StudentLoanAssistancePaymentStatus;
  createdAt: string;
  transactions: StudentLoanAssistancePaymentTransaction[];
}

export enum StudentLoanAssistancePaymentStatus {
  Setup = 'Status',
  Active = 'Active',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
}

export enum StudentLoanAssistancePaymentPlan {
  Card990 = 'Card990',
  Card3Payments = 'Card3Payments',
  Card12Payments = 'Card12Payments',
  PayrollDeduction12Payments = 'PayrollDeduction12Payments',
}

export interface StudentLoanAssistancePaymentTransaction {
  id: string;
  timestamp: string;
  amount: number;
  memo: string;
}

export interface Employer {
  employerName?: string;
  payFrequency?: string;
  hireDate?: string;
  jobTitle?: string;
  annualIncome?: number;
  logo?: string;
  linkItemId?: string;
  payrollProvider?: PayrollProviderName;
  verifiedName?: boolean;
  verifiedAnnualIncome?: boolean;
  verifiedHireDate?: boolean;
}

export interface ApplicationStatusUpdateResponse {
  status: ApplicationStatusName;
}

export interface ApplicationUpdateCitizenshipResponse {
  citizenship: string;
}

interface ResumeProcessApplicationData {
  application?: GetApplicationData;
  apr?: number;
  currentVerificationStep?: VerificationStep;
  isLoading: boolean;
  isApplicationStatusUpdating: boolean;
  isAprLoading: boolean;
  forceApplicationApproved?: boolean;
}

export interface CreateApplicationResponse {
  passed: boolean;
  data: {
    application_id: string;
  };
}

export interface GetApplicationDataResponse {
  application: GetApplicationData;
}

export interface GetApplicationAprResponse {
  apr: number;
}

const initialState: ResumeProcessApplicationData = {
  isLoading: false,
  isApplicationStatusUpdating: false,
  isAprLoading: false,
};

const applicationData = createSlice({
  name: 'applicationData',
  initialState,
  reducers: {
    setCurrentVerificationStep: (state, { payload }: PayloadAction<VerificationStep>) => {
      state.currentVerificationStep = payload;
    },
    completeVerificationStep: (state, { payload }: PayloadAction<VerificationStep>) => {
      state.application!.verificationStepProgress![payload] = true;
    },
    setApplicationApproved: (state) => {
      state.forceApplicationApproved = true;
      state.application!.status = ApplicationStatusName.Funded;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getApplicationData.fulfilled, (state, { payload }: PayloadAction<GetApplicationDataResponse>) => {
      state.application = parseApplication(payload.application);
      if (state.forceApplicationApproved) {
        state.application!.status = ApplicationStatusName.Funded;
      }
      state.isLoading = false;
    });
    builder.addCase(updateEmploymentData.fulfilled, (state, { payload }: PayloadAction<GetApplicationDataResponse>) => {
      state.application = parseApplication(payload.application);
      if (state.forceApplicationApproved) {
        state.application!.status = ApplicationStatusName.Funded;
      }
    });
    builder.addCase(getApplicationData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getApplicationData.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateApplicationStatus.pending, (state) => {
      state.isApplicationStatusUpdating = true;
    });
    builder.addCase(updateApplicationStatus.rejected, (state) => {
      state.isApplicationStatusUpdating = false;
    });
    builder.addCase(
      updateApplicationStatus.fulfilled,
      (state, { payload }: PayloadAction<ApplicationStatusUpdateResponse>) => {
        state.isApplicationStatusUpdating = false;
        if (!state.forceApplicationApproved) {
          state.application!.status = payload.status;
        }
      },
    );
    builder.addCase(updateSelectedTradelines.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateSelectedTradelines.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      updateSelectedTradelines.fulfilled,
      (state, { payload }: PayloadAction<GetApplicationDataResponse>) => {
        state.isLoading = false;
        if (payload) {
          state.application! = parseApplication(payload.application);
        }
      },
    );
    builder.addCase(updateAdditionalFunds.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateAdditionalFunds.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      updateAdditionalFunds.fulfilled,
      (state, { payload }: PayloadAction<GetApplicationDataResponse>) => {
        state.isLoading = false;
        if (payload) {
          state.application! = parseApplication(payload.application);
        }
      },
    );
    builder.addCase(verifyApplication.fulfilled, (state, { payload }: PayloadAction<VerificationResult>) => {
      if (payload.applicationStatus && !state.forceApplicationApproved) {
        state.application!.status = payload.applicationStatus;
      }
    });
    builder.addCase(getApplicationApr.pending, (state) => {
      state.isAprLoading = true;
    });
    builder.addCase(getApplicationApr.rejected, (state) => {
      state.isAprLoading = false;
    });
    builder.addCase(getApplicationApr.fulfilled, (state, { payload }: PayloadAction<GetApplicationAprResponse>) => {
      state.isAprLoading = false;
      state.apr = payload.apr;
    });
    builder.addCase(uploadDocuments.fulfilled, (state, { payload }: PayloadAction<UploadDocumentsResponse>) => {
      state.application && (state.application.verificationStepProgress = payload.verificationStepProgress);
    });
    builder.addCase(joinDebtConsolidationWaitlist.pending, (state) => {
      state.isLoading = true;
    });
  },
});

export const { setCurrentVerificationStep, completeVerificationStep, setApplicationApproved } = applicationData.actions;

export default applicationData.reducer;

const parseApplication = (application: GetApplicationData): GetApplicationData => ({
  ...application,
  hardOffer: application.hardOffer ? JSON.parse(application.hardOffer as string) : null,
  debtSummary: application.debtSummary ? JSON.parse(application.debtSummary as string) : null,
});
