import React from 'react';
import clsx from 'clsx';
import { ReactComponent as PlusIcon } from 'images/plus-icon-thick.svg';
import { ReactComponent as PlusIconWhite } from 'images/plus-icon-thick-white.svg';
import { useSelector } from 'react-redux';
import { ReactComponent as LoveIcon } from 'images/love-icon.svg';
import useLayoutTheme from 'hooks/useLayoutTheme';
import { ReactComponent as PlanneryLogo } from 'images/plannery-logo.svg';
import { Themes } from 'styles/themes/ThemeTypes';
import { getTaglineGroupName } from 'enums/ProfessionGroup';
import { getProfessionGroup } from 'selectors/professionGroup';
import { useLocation } from 'react-router-dom';
import { RoutePath } from 'enums/Routes';
import { usePartnerData } from 'enums/PartnerName';

import YourSavingDropdown from 'components/Verification/YourSavingsDropdown';

import styles from './Header.module.scss';

const Header = () => {
  const { pathname } = useLocation();
  const { theme, themeStyles } = useLayoutTheme();
  const { professionGroup } = useSelector(getProfessionGroup);
  const { partnerName, logoExtension } = usePartnerData();
  const showYourSavingsDropdown =
    pathname === RoutePath.VerificationSteps ||
    pathname === RoutePath.SetupAccount ||
    pathname === RoutePath.SetupDeduction;

  const isWhiteLogo = theme === Themes.BLUE || theme === Themes.PURPLE || theme === Themes.DARK;

  if (pathname === RoutePath.YourFinances) {
    return null;
  }

  return (
    <div className={styles.headerSpacingContainer}>
      <div className={clsx(styles.header, themeStyles.header, showYourSavingsDropdown && styles.yourSavingsDropdown)}>
        <div className={styles.topRow}>
          <PlanneryLogo className={clsx(styles.logo, themeStyles.logo)} />
          {partnerName && (
            <>
              {isWhiteLogo ? <PlusIconWhite className={styles.plus} /> : <PlusIcon className={styles.plus} />}
              <img
                className={styles.logo}
                src={`https://assets.planneryapp.com/media/partners/${partnerName}/logo${isWhiteLogo ? '-white' : ''}.${
                  logoExtension ?? 'png'
                }`}
                alt={partnerName}
              />
            </>
          )}
        </div>
        {!partnerName && !showYourSavingsDropdown && (
          <span className={clsx(styles.lovePhrase, themeStyles.lovePhrase)}>
            We <LoveIcon className={styles.loveIcon} /> {getTaglineGroupName(professionGroup)}
          </span>
        )}
      </div>
      {showYourSavingsDropdown && <YourSavingDropdown />}
    </div>
  );
};

export default Header;
